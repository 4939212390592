/* .Home span {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: aqua;
    color: #000;
    margin: 5px;
} */

.appcontainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}
.responsive{
    max-width:100%;
    height:400px;
}
.appcontainer .ant-space-item{
    width: 100%;
}

.stepStyle{
    height:100%;
}


.shapBox{
    text-align: center;
    margin-bottom:50px;
}
.ElementBoxes{
    background: #fff;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 5px 15px 0px #00000024;
    padding: 30px 25px;
    text-align: center;
    
}
.newbox{
    background:#daf7ff;
    padding:40px;
    border-radius: 50px;
    margin:20px;
}

/* .bg:after {
    content: "";
        border: 50px solid #000;
        border-width: 0px 0px 100px 0px;
        border-color: red green blue orange;
        border-radius: 0px 0px 20% 50px;
        position: absolute;
        display: block;
        bottom: 0;

} */
.actionBTNs{
    margin-top:60px;
    display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
}

.actionBTNs button{
    padding: 10px 20px !important;
    height: auto;
    font-size: 18px;
    min-width: 136px;
}
.Elements{
    height: auto;
    /* overflow-y: auto; */
    margin-top:10px;
    margin-bottom:10px;
}
.boxTitle{
    margin-top: 0;
    margin-bottom: 10px;
    color: #00c8ff;
    font-weight: 900 !important;
    text-transform: uppercase;
    line-height: 32px;
    font-size: 32px !important;
}

.pricebtn {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
}

.stepssettings {
    width: 100%;
    /* overflow-x: auto; */
}