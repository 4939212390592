
.site-layout-content {
    min-height: 280px;
    padding: 24px;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

  .mainContent{
    /* height: calc(100vh - 131px); */
    /* min-height:600px; */
    height:auto;
    padding:0px 50px;
  }