.Container{
    justify-content: center;
    align-items: center;
    /* height:100%; */
    text-align: center;
    width:100%;
}
.ContainerAlignment{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-content: center;
}

.centerAlign{
    align-items: center;
    justify-content: center;
}

.fullWidth{
    width:100%;
}
.itemdiv{
    width: 40px;
    height: 40px;
    position: relative;
    margin: 10px;
    border-radius: 15px;
    font-size: 30px;
    padding: 0;
    text-align: center;
    margin-top:15px;
    margin-bottom:25px;
    border: 2px solid var(--secondary-color);
    border-radius: 100px;
    vertical-align: top !important;
}
.itemdiv:hover{
    border: 2px solid var(--primary-color);
    cursor: pointer;
}
.badge{
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 14px;
    background: var(--primary-color);
    height: 20px;
    line-height: 20px;
    color: #fff;
    padding: 0 5px;
    border-radius: 20px;
    border-radius: 100px;
    
}
.itemdiv.active{
    background-color: var(--secondary-color);
    border: 2px solid var(--primary-color);
    /* box-shadow:0 0 5px 0 #f00; */
}

.colorBox{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    border:2px solid #fff;
    border-radius: 100px;
}

.itemdiv .Title{
    font-size: 12px !important;
    margin-top: 14px !important;
    color: #5a5a5a;
    line-height: 15px;
    margin-top:5px !important;
}

.sectionHeight{
    max-height:212px;
    overflow-y: auto;
}
.ImageBox{
    display: inline-block;
    vertical-align: top;
}
.colorBox div{
    vertical-align: top;
}
.inline{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:5px
}
.inline Image{
    border:2px solid #fff;
}
.inline .colorBox{
    width:35px;
    height:35px;
    vertical-align: middle;
    border-radius: 12px;
    border: 2px solid var(--primary-color);
    border-radius: 40px;
    /* border: 2px solid #fff; */
    margin-right:8px
}
.inline .ImageBox{
    border:2px solid #fff;
}
.inline .Title{
    margin-top:0;
    margin-bottom:0;
    font-size:13px;
}