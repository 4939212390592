:root{
  --primary-color:#00c8ff;
  --secondary-color:#daf7ff;
  --trinary-color:#edffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-content .ant-space-item{
  width:100%;
}

main{
  background:#ffffff;
}
.ant-btn-primary {
  background: var(--primary-color);
}
.ant-btn-primary:hover {
  background: var(--primary-color) !important;
  box-shadow: 0 0 20px 1px var(--trinary-color);
}

.ant-btn {
  padding: 10px 30px !important;
  height: auto;
  font-size: 18px;
}

h3 {
  font-size: 34px;
}

h4 {
  font-size: 26px !important;
}

/* .ant-steps-item-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;

}

 */

 body :where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-process .ant-steps-item-icon {
   background-color: var(--primary-color) !important;
   border-color: var(--primary-color) !important;
 }
 :where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
  background-color: #00c8ff !important;
 }

span.ant-input-group-addon{
  background-color:var(--primary-color) !important;
  color:#fff !important;
}

/* .ant-steps-item-title{
  font-size:13px !important;
  font-weight:500;
} */

span.ant-upload-list-item-actions a:first-child {
  display: none;
}

span.ant-upload-list-item-actions button {
  padding: 0 !important;
}


/* Fabric Canvas component design */
.Fabdesign {
  display: flex;
  justify-content: center;
  align-items: center;
}
.samplecanvas {
  /* border: 1px solid #000; */
  border: 1px solid #fff;
  width: 500px;
  height: 500px;
  box-shadow: 0 0 15px 0px var(--secondary-color);
}
.samplecanvasborder{
      border: 1px solid #daf7ff;
        box-shadow: 0px 0px 25px 10px #d1d1d182;
}
.Qubic {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 500px;
  height: 500px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s linear;
}
.btncross {
  position: absolute;
  top: -24px;
    right: -19px;
  border: 1px solid #000;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  outline: none;
  padding: 2px 2px 2px 2px;
  z-index: 2;
  cursor: pointer;
}
/* .Scaled {
  transform: scale(0.1);
} */
.Qubic.Scaled {
  opacity: 1;
  z-index: 1;
}
.Qubic.Scaled > button {
  display: none;
}
.Qubic > span {
  display: none;
}
.Qubic.Scaled > span {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 22%);
  z-index: 2;
  cursor: pointer;
}
.Qubic.Scaled:hover > span {
  display: flex;
}
.btncubic {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: -30px;
}

.cubepreview {
  display: flex;
  justify-content: center;
}
.cubepreview .Qubic {
  position: relative;
}

.visibilitynone {
  display: none !important;
}


/* 3D Cube styles */
.viewport {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  -webkit-perspective-origin: 50% 200px;
  -moz-perspective-origin: 50% 200px;
  -ms-perspective-origin: 50% 200px;
  -o-perspective-origin: 50% 200px;
  perspective-origin: 50% 200px;
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  -webkit-box-reflect: below 170px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0%, transparent), to(rgba(250, 250, 250, 0.1))); }

.cube {
  position: relative;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(136deg) rotateY(1122deg);
  -moz-transform: rotateX(136deg) rotateY(1122deg);
  -ms-transform: rotateX(136deg) rotateY(1122deg);
  -o-transform: rotateX(136deg) rotateY(1122deg);
  transform: rotateX(136deg) rotateY(1122deg); }

.cube > div {
  overflow: hidden;
  position: absolute;
  opacity: 1;
  height: 200px;
  width: 200px;
  background-image: url("https://jordizle.com/static/img/codepen/blank.png");
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  -o-touch-callout: none;
  touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: 1px solid #ededed;
}

.cube > div > div.cube-image {
  width: 200px;
  height: 200px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  line-height: 200px;
  font-size: 80px;
  text-align: center;
  color: #1b9bd8;
  -webkit-transition: color 600ms;
  -moz-transition: color 600ms;
  -ms-transition: color 600ms;
  -o-transition: color 600ms;
  transition: color 600ms; }
  .cube > div > div.cube-image.active {
    color: red; }

.cube > div:hover {
  cursor: pointer; }

.cube > div:active {
  cursor: pointer; }

.cube > div:first-child {
  -webkit-transform: rotateX(90deg) translateZ(100px);
  -moz-transform: rotateX(90deg) translateZ(100px);
  -ms-transform: rotateX(90deg) translateZ(100px);
  -o-transform: rotateX(90deg) translateZ(100px);
  transform: rotateX(90deg) translateZ(100px);
  outline: 1px solid transparent; }

.cube > div:nth-child(2) {
  -webkit-transform: translateZ(100px);
  -moz-transform: translateZ(100px);
  -ms-transform: translateZ(100px);
  -o-transform: translateZ(100px);
  transform: translateZ(100px);
  outline: 1px solid transparent; }

.cube > div:nth-child(3) {
  -webkit-transform: rotateY(90deg) translateZ(100px);
  -moz-transform: rotateY(90deg) translateZ(100px);
  -ms-transform: rotateY(90deg) translateZ(100px);
  -o-transform: rotateY(90deg) translateZ(100px);
  transform: rotateY(90deg) translateZ(100px);
  outline: 1px solid transparent; }

.cube > div:nth-child(4) {
  -webkit-transform: rotateY(180deg) translateZ(100px);
  -moz-transform: rotateY(180deg) translateZ(100px);
  -ms-transform: rotateY(180deg) translateZ(100px);
  -o-transform: rotateY(180deg) translateZ(100px);
  transform: rotateY(180deg) translateZ(100px);
  outline: 1px solid transparent; }

.cube > div:nth-child(5) {
  -webkit-transform: rotateY(-90deg) translateZ(100px);
  -moz-transform: rotateY(-90deg) translateZ(100px);
  -ms-transform: rotateY(-90deg) translateZ(100px);
  -o-transform: rotateY(-90deg) translateZ(100px);
  transform: rotateY(-90deg) translateZ(100px);
  outline: 1px solid transparent; }

.cube > div:nth-child(6) {
  -webkit-transform: rotateX(-90deg) rotate(180deg) translateZ(100px);
  -moz-transform: rotateX(-90deg) rotate(180deg) translateZ(100px);
  -ms-transform: rotateX(-90deg) rotate(180deg) translateZ(100px);
  -o-transform: rotateX(-90deg) rotate(180deg) translateZ(100px);
  transform: rotateX(-90deg) rotate(180deg) translateZ(100px);
  outline: 1px solid transparent; }

object {
  opacity: 0.5; }

object:hover {
  opacity: 1; }

@media (max-width: 640px) {
  .viewport {
    -webkit-transform: scale(0.6, 0.6);
    -moz-transform: scale(0.6, 0.6);
    -ms-transform: scale(0.6, 0.6);
    -o-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6); } }


    ul.ant-picker-ranges {
      margin-top: 0 !important;
  }
  
  .ant-picker-ok button {
      padding: 0px 7px !important;
  }

  /* .ant-steps-item-active .ant-steps-item-icon {
    background: #00c8ff !important;
    border-color: #00c8ff !important;
} */

button.ant-btn-default:hover {
  background: #d9f6fe;
}

button.ant-btn-default {
  transition: all 0.3s;
}

button.ant-btn-primary {
  transition: all 0.3s;
}

button.ant-btn-primary:hover {
  background: #04b8eb !important;
}