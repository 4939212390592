.Container{
    justify-content: center;
    align-items: center;
    /* height:100%; */
    text-align: center;
    width:100%;
}
.ContainerAlignment{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-content: center;
    
}

.centerAlign{
    align-items: center;
    justify-content: center;
    /* max-height: 274px;
        overflow-y: auto; */
}

.fullWidth{
    width:100%;
}
.itemdiv{
    width: 90px;
    height: 90px;
    position: relative;
    margin:15px;
    border-radius: 15px;
    font-size: 30px;
    padding: 0;
    text-align: center;
    margin-top:15px;
    margin-bottom:25px;
    border: 2px solid #daf7ff;
}
.itemdiv:hover{
    border: 2px solid #00c8ff;
    cursor: pointer;
}
.badge{
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 14px;
    background: #00c8ff;
    height: 20px;
    line-height: 20px;
    color: #fff;
    padding: 0 5px;
    border-radius: 20px;
    
}
.itemdiv.active{
    background-color: #daf7ff;
    border: 2px solid #00c8ff;
}

.shapeBox .Image{
    width:60px !important;
    height:60px !important;
    object-fit: contain;
    margin-top:15px
}

.shapeBox .Title{
    font-size: 13px !important;
    margin-top: 18px !important;
    color: #5a5a5a;
    line-height: 15px;
}

.sectionHeight{
    /* max-height:250px;
    overflow-y: auto; */
}