.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'Classic';
  src: url('/public/fonts/AppleGaramondLight.eot');
  src: url('/public/fonts/AppleGaramondLight.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/AppleGaramondLight.woff2') format('woff2'),
      url('/public/fonts/AppleGaramondLight.woff') format('woff'),
      url('/public/fonts/AppleGaramondLight.ttf') format('truetype'),
      url('/public/fonts/AppleGaramondLight.svg#AppleGaramondLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'apollo';
  src: url('/public/fonts/Vogue-Regular.eot');
  src: url('/public/fonts/Vogue-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/Vogue-Regular.woff2') format('woff2'),
      url('/public/fonts/Vogue-Regular.woff') format('woff'),
      url('/public/fonts/Vogue-Regular.ttf') format('truetype'),
      url('/public/fonts/Vogue-Regular.svg#Vogue-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Modern';
  src: url('/public/fonts/ArialMT.eot');
  src: url('/public/fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/ArialMT.woff2') format('woff2'),
      url('/public/fonts/ArialMT.woff') format('woff'),
      url('/public/fonts/ArialMT.ttf') format('truetype'),
      url('/public/fonts/ArialMT.svg#ArialMT') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Script';
  src: url('/public/fonts/MurrayHillOpti-Regular.eot');
  src: url('/public/fonts/MurrayHillOpti-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/MurrayHillOpti-Regular.woff2') format('woff2'),
      url('/public/fonts/MurrayHillOpti-Regular.woff') format('woff'),
      url('/public/fonts/MurrayHillOpti-Regular.ttf') format('truetype'),
      url('/public/fonts/MurrayHillOpti-Regular.svg#MurrayHillOpti-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}









/* @font-face {
  font-family: 'amadeo';
  src:  url('/public/fonts/amadeo.woff2') format('woff2'),
        url('/public/fonts/amadeo.woff') format('woff');
}

@font-face {
  font-family: 'apollo';
  src:  url('/public/fonts/apollo.woff2') format('woff2'),
        url('/public/fonts/apollo.woff') format('woff');
} */

@font-face {
  font-family: 'arial';
  src:  url('/public/fonts/arial.woff2') format('woff2'),
        url('/public/fonts/arial.woff') format('woff');
}

.arial{
  font-family: 'arial';
}
.apollo, .Apollo{
  font-family: 'apollo';
}
.amadeo{
  font-family: 'amadeo';
}

header.ant-layout-header {
    background: #fff !important;
    height: auto !important;
    line-height: initial !important;
    padding-top: 10px !important;
}

.btncross, .btncubic {
    position: absolute;
    top: -15px !important;
    right: -20px !important;
    z-index: 9 !important;
    border: 1px solid #00c8ff !important;
    background: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0 !important;
    border-radius: 50px;
}
.btncubic img {
    margin: 0 auto;
    display: block;
    padding: 0 !important;
}

button.btncross {
    font-size: 18px;
}

.Fabdesign {
    margin-top: 30px;
}


.relativethumb {
    position: relative;
}

.btn-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9;
    padding: 0 !important;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 30px;
    background: #f00 !important;
    color: #fff !important;
    font-size: 15px;
    text-align: center;
}

.btn-remove span.anticon.anticon-close-circle {position: relative;height: 22px;line-height: 22px;display: block;text-align: center;}

:where(.css-dev-only-do-not-override-diro6f).ant-picker-dropdown .ant-picker-time-panel-column::after {
    height: auto !important;
}

:where(.css-dev-only-do-not-override-diro6f).ant-spin-nested-loading>div>.ant-spin{
  max-height: none !important;
}


:where(.css-dev-only-do-not-override-diro6f).ant-spin .ant-spin-dot-item {
  background: #00c8ff !important;
}

:where(.css-dev-only-do-not-override-diro6f).ant-btn-default {
  border-color: #00c8ff !important;
  color: #00c8ff !important;
}


h4 {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #00c8ff !important;
  text-transform: uppercase;
}

.mt--60{
  margin-top:-60px !important;
  position: relative;
}

.btn-remove {
  color: #fff !important;
  border-color: #fff !important;
}

footer {
  margin-top:50px;
  background: #00c8ff0d !important
}


section {
  background: #f6feff82 !important;
}

main {
  background: #f6feff82;
}

header.ant-layout-header {
  background: #f6feff82 !important;
}

:where(.css-diro6f).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #00c8ff !important;
  border-color: #00c8ff !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
  height: 5px !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
  background: rgb(52, 211, 255) !important;
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY{
  width:5px !important;
}
.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY{
  background: rgb(52, 211, 255) !important;
}


/*Mobile*/

@media (max-width: 1399.98px) {}


@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
  .mt--60 {
      margin-top: 20px !important;
      position: relative;
    }
}

@media (max-width: 767.98px) {}


@media (max-width: 575.98px) {}